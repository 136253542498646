import { gql } from '@apollo/client'

const PENDO_TOKEN_QUERY = `
query GetPendoToken($userId: String! $userName: String!) {
  getPendoToken(userId: $userId userName: $userName) {
    jwt
    signingKeyName
  }
}
`
export const GET_PENDO_TOKEN = gql`
  ${PENDO_TOKEN_QUERY}
`
